<template>
  <div class="loin-wrap container" v-wechat-title="protalTitle">
    <div class="left">
      <div class="header">
        <h2 class="animation a1" v-if="domainSettings">{{ domainSettings.studentPlatformName || '' }}</h2>
        <h4 class="animation a2">请输入账号及密码登入学习</h4>
      </div>
      <div class="form">
        <input
          class="form-field animation a3"
          placeholder="请输入学号或身份证号"
          v-model="studentData.username"
          @keyup.enter="onLogin"
        />
        <input
          type="password"
          class="form-field animation a4"
          placeholder="请输入密码"
          v-model="studentData.password"
          @keyup.enter="onLogin"
        />
        <div class="d-flex align-center">
          <input
            class="form-field animation a3"
            placeholder="请输入验证码"
            v-model="studentData.imageCode"
            @keyup.enter="onLogin"
          />
          <img
            :style="{
              width: '80px',
              height: '46px',
              marginTop: '20px',
              objectFit: 'fill'
            }"
            class="verify-image"
            :src="verifyImageUrl"
            alt="验证码"
            @click="onVerifyImageClick"
          />
        </div>
        <p class="forget animation a5"><a @click="$router.push({ name: 'forget' })">忘记密码?</a></p>
        <button class="animation a6" @click="onLogin">登录</button>
        <div style="margin-top: 30px;margin-left: 72px" v-if="showWxLogin && isShowWxLogin === 'Y'" @click="onWxLogin">
          <img src="@/assets/img/login/wx.png" alt="" style="width: 50px"/>
          <p style="margin-top:-30px"><a  style="margin-left:60px;color: rgb(0, 0, 0);">微信账号登录</a></p>
        </div>
        <div style="margin-top: 30px;" v-if="showBinding">
          <p ><input type="checkbox" id="checkbox" v-model="checkBinding">登录成功后关联微信，下次可用微信直接登录</p>
        </div>
      </div>

      <br /><br /><br /><br /><br /><br />
      <div
        class="copyright"
        v-html="domainSettings.studentLoginCopyright"
        v-if="domainSettings && domainSettings.studentLoginCopyright"
      ></div>
    </div>
    <div class="right"></div>
    <a-modal
      :visible="firstLoginVisible"
      title="首次登录-密码修改"
      :footer="false"
      :closable="false"
      :maskClosable="false"
      @cancel="firstLoginVisible = false"
    >
      <a-form :form="resetForm" @submit="onFirstLoginSubmit" :label-col="{ span: 5 }" :wrapper-col="{ span: 14 }">
        <!-- <div class="hide">
          <a-input></a-input>
          <a-input type="password"></a-input>
        </div> -->
        <a-form-item label="手机号">
          <a-input
            placeholder="请输入手机号"
            v-decorator="verifyFormRule.phoneNumber"
            :autocomplete="'new-password'"
          ></a-input>
        </a-form-item>
        <a-form-item label="验证码">
          <a-row :gutter="10">
            <a-col :span="15">
              <a-input placeholder="请输入验证码" v-decorator="verifyFormRule.code" auto-complete="off"></a-input>
            </a-col>
            <a-col :span="9">
              <count-down ref="countDown" @send="handleSend"></count-down>
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item label="新密码">
          <a-input placeholder="请输入密码" type="password" v-decorator="verifyFormRule.newPassword"></a-input>
        </a-form-item>
        <a-form-item label="新密码">
          <a-input placeholder="请重复密码" type="password" v-decorator="verifyFormRule.confirmNewPassword"></a-input>
        </a-form-item>
        <div class="text-center">
          <a-button class="cus-button" type="primary" html-type="submit" :loading="btnLoading">确认修改</a-button>
        </div>
      </a-form>
    </a-modal>

    <a-modal  :visible="wxLoginVisible"
              :footer="false"
              width="350px"
              :maskClosable="false"
              @cancel="wxLoginVisible = false">
      <!-- 放置二维码的div -->
      <div id="login_container">
        <iframe ></iframe>
      </div>
      <div style="text-align: center; margin-top: -20px">{{ protalTitle || '' }}</div>
    </a-modal>

    <a-modal  :visible="bindingWxLoginVisible"
              :footer="false"
              width="350px"
              :closable="false"
              :maskClosable="false"
              @cancel="bindingWxLoginVisible = false">
      <p>您好，{{nickname}}</p>
      <p>您还没有关联学习平台账号，请选择一项操作：</p>
      <a-button style="margin-top:30px;margin-right:30px;" size="small" @click="cancel">取消</a-button>
      <a-button style="margin-right:10px;" type="primary" size="small" @click="binding">登录现有账号</a-button>
    </a-modal>
  </div>
</template>

<script>
import md5 from 'md5'
import _ from 'lodash'
import api from '@/api'
import { mapState } from 'vuex'
import CountDown from '@/components/CountDown'
import { isPhone, isPassword, isSmsCode } from '@/utils/validate'
import { message } from 'ant-design-vue'
import helper from '@/utils/helper'
import wxlogin from '@/utils/wxLogin'

// TODO: 测试用
let studentData = {}
if (process.env.NODE_ENV === 'development') {
  studentData = {
    username: '156575755(4)',
    password: '19771116'
  }
}
export default {
  name: 'login_student',
  components: {
      CountDown
  },
  data() {
    return {
      studentData: studentData,
      redirect: '/',
      verifyImageUrl: '',
      organizationId: '',
      isShowWxLogin:'',
      studId: '',
      protalTitle: '',
      firstLoginVisible: false,
      wxLoginVisible: false,
      bindingWxLoginVisible: false,
      nickname: '',
      refreshToken: '',
      showWxLogin: true,
      showBinding: false,
      checkBinding: false,
      firstLoginForm: {},
      resetForm: this.$form.createForm(this, { name: 'form' }),
      verifyFormRule: {
        phoneNumber: [
          'phoneNumber',
          {
            rules: [{ required: true, message: '手机号不能为空' }, { validator: this.validatePhone }],
            initialValue: ''
          }
        ],
        code: [
          'code',
          {
            rules: [{ required: true, message: '请输入验证码' }, { validator: this.validateSms }],
            initialValue: ''
          }
        ],
        newPassword: [
          'newPassword',
          {
            rules: [{ required: true, message: '请输入验证码' }],
            initialValue: ''
          }
        ],
        confirmNewPassword: [
          'confirmNewPassword',
          {
            rules: [{ required: true, message: '请输入验证码' }, { validator: this.validateNewPassword }],
            initialValue: ''
          }
        ]
      },
      btnLoading: false,
    }
  },
  computed: {
    ...mapState(['domainSettings'])
  },
  mounted() {
    const { redirect, access_token } = this.$route.query
    this.redirect = redirect || '/student'
    if (access_token) {
      this.AutoLogin(access_token)
    }
    this.initVerifyImage()
    this.initOrganizationByDomain()
    /*监听路由是否有code值*/
    if(this.$route.query.code != undefined) {
        console.log("路由有code值")
        //请求后端微信登录接口
        this.getWeixinLogin(this.$route.query.code,this.$route.query.state);
    }
  },
  methods: {
    async initOrganizationByDomain() {
      const domain = window.location.origin || 100000
      let res = await api.protal.getOrganizationByDomain({ domain })
      if (res.code === 200 && res.data) {
        this.organizationId = res.data.organizationId
        this.protalTitle = res.data.organizationName || ''
        this.isShowWxLogin = res.data.isShowWxLogin
        // 字符串存到数据库后再取出， 空格的编码从 '%20' -> '%C2%A0', 必须经过转换后才能正常渲染
        let str = res.data.studentLoginCopyright
        str = encodeURI(str).replaceAll('%C2%A0', '%20')
        str = decodeURI(str)
        res.data.studentLoginCopyright = str

        localStorage.setItem('organizationId', res.data.organizationId)
        localStorage.setItem('isShowWxLogin', res.data.isShowWxLogin)
        this.$store.commit('setDomainSettings', res.data)
      } else {
        // TODO: 测试用
        if (
          process.env.NODE_ENV === 'development' ||
          (process.env.NODE_ENV === 'production' && process.env.VUE_APP_API_BASE_URL)
        ) {
          this.organizationId = 100000
          localStorage.setItem('organizationId', 100000)
        }
      }
      // TODO: 动态设置图标
      var link = document.querySelector("link[rel*='icon']") || document.createElement('link')
      link.type = 'image/x-icon'
      link.rel = 'shortcut icon'
      link.href = `./${this.organizationId}.ico`
      document.getElementsByTagName('head')[0].appendChild(link)
    },
    async initVerifyImage() {
      // TODO: 环境配置
      let res = await api.student.verifyImage()
      if (res.code === 200) {
        this.verifyImageUrl =
          (process.env.NODE_ENV === 'development'
            ? '/api'
            : process.env.VUE_APP_API_BASE_URL
            ? process.env.VUE_APP_API_BASE_URL
            : '/') + res.data.imageURL
        this.studentData.imageKey = res.data.imageKey
      }
    },
    onVerifyImageClick: _.throttle(function() {
      this.initVerifyImage()
    }, 3000),
    async AutoLogin(accessToken) {
      helper.clearUserInfo()
      let res = await api.student.quickLogin({ access_token: accessToken })
      if (res.code === 200) {
        await this.$store.dispatch('loginSuccess', {
          user: res.data || {},
          role: 'STUDENT'
        })
        this.$router.replace(this.redirect)
      }
    },
    async onLogin() {
      const data = {
        ...this.studentData,
        organizationId: this.organizationId,
          binding: this.checkBinding,
          refreshToken: this.refreshToken
      }
      try {
        let checkBinding = this.checkBinding;
        // 判断是否选择了绑定微信登录
        if (checkBinding) {
            let res = await api.student.bdingLogin(data)
            if (res.code === 200) {
                if (res.data.loginTime === 0) {
                    // 此时不能算登录成功  但是后续接口需要用到studId ，所以在这里先把studId 存进storage
                    localStorage.setItem('userId', res.data.studId)
                    this.firstLoginVisible = true
                    this.$nextTick(() => {
                        this.resetForm.setFieldsValue({
                            phoneNumber: res.data.phone,
                            code: '',
                            newPassword: '',
                            confirmNewPassword: ''
                        })
                    })
                } else {
                    this.$message.info('微信关联成功！')
                    await this.$store.dispatch('loginSuccess', {
                        user: res.data || {},
                        role: 'STUDENT'
                    })
                    this.$router.replace(this.redirect)
                    return;
                }
            }
        }
        let res = await api.student.login(data)
        if (res.code === 200) {
          this.studentData = {}
          this.studId = res.data.studId
          if (res.data.loginTime === 0) {
            // 此时不能算登录成功  但是后续接口需要用到studId ，所以在这里先把studId 存进storage
            localStorage.setItem('userId', res.data.studId)
            this.firstLoginVisible = true
            this.$nextTick(() => {
              this.resetForm.setFieldsValue({
                phoneNumber: res.data.phone,
                code: '',
                newPassword: '',
                confirmNewPassword: ''
              })
            })
          } else {
            await this.$store.dispatch('loginSuccess', {
              user: res.data || {},
              role: 'STUDENT'
            })
            this.$router.replace(this.redirect)
          }
        }
      } catch (err) {
        this.initVerifyImage()
      }
    },
    validatePhone(rule, value, callback) {
      const form = this.resetForm
      const phone = form.getFieldValue('phoneNumber')
      if (value && !isPhone(phone)) {
        callback('手机号格式不正确')
      } else {
        callback()
      }
    },
    validateSms(rule, value, callback) {
      const form = this.resetForm
      const code = form.getFieldValue('code')
      if (value && !isSmsCode(code)) {
        callback('验证码格式不正确')
      } else {
        callback()
      }
    },
    validateNewPassword(rule, value, callback) {
      const form = this.resetForm
      const newPassword = form.getFieldValue('newPassword')
      if (value !== newPassword) {
        callback('密码不一致')
      } else {
        callback()
      }
    },
    handleSend() {
      this.resetForm.validateFields(['phoneNumber'], async (err, values) => {
        if (!err) {
          // 发送验证码
          await this.$refs['countDown'].countDown()
          await this.sedSms()
        }
      })
    },
    async sedSms() {
      try {
        const phoneNumber = this.resetForm.getFieldValue('phoneNumber')
        await api.student.sendSmsNotVerifyUser(this.organizationId, phoneNumber)
      } catch (e) {}
    },
    async checkSms() {
      try {
        this.btnLoading = true
        const phoneNumber = this.resetForm.getFieldValue('phoneNumber')
        const code = this.resetForm.getFieldValue('code')
        await api.student.checkSmsCode(phoneNumber, code)
        this.btnLoading = false
        this.phoneNumber = phoneNumber
      } catch (e) {
        this.btnLoading = false
      }
    },
    onFirstLoginSubmit(e) {
      e.preventDefault()
      this.resetForm.validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          try {
            this.btnLoading = true
            // await this.checkSms()
            const newPassword = this.resetForm.getFieldValue('newPassword')
            const phoneNumber = this.resetForm.getFieldValue('phoneNumber')
            const code = this.resetForm.getFieldValue('code')
            const { studId } = this
            const data = { password: md5(newPassword), phone: phoneNumber, code, studId, code }
            await api.student.updatePasswordAndPhone(data)
            message.success('密码修改成功，请使用新密码重新登录。', 8)
            this.btnLoading = false
            this.firstLoginVisible = false
          } catch (e) {
            this.btnLoading = false
          }
        }
      })
    },
    async onWxLogin() {
        //判断设备是否移动端
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        if (flag) {
            this.$message.info("请安装APP");
            return ;
        }
        //生成微信登录二维码
        this.wechatCode()
        this.wxLoginVisible = true
    },
    async wechatCode () {
        let organizationId = localStorage.getItem('organizationId');
        let url = process.env.VUE_APP_WX_API_BASE_URL + "/student/redirect?organizationId="
            + organizationId + "&type=login&env=" + process.env.NODE_ENV;
        console.log(url)
        let wxAppId = process.env.VUE_APP_WX_APP_ID;
        let state = Math.ceil(Math.random()*1000);
        //记住一定要注明文件类型是css
        var blob = new Blob([""],{type: "text/css;charset=utf-8"});
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = function(e) {
            var obj = new WxLogin({
                self_redirect: false,  //值为true时，直接在二维码的弹窗中进行页面跳转及其余操作,
                id:"login_container", //div的id
                appid: wxAppId,
                scope: "snsapi_login", //固定内容
                state: state,
                redirect_uri:encodeURIComponent(url), //回调地址
                //href: "data:text/css;base64,LmltcG93ZXJCb3ggLmluZm8ge2Rpc3BsYXk6IG5vbmU7fQ=="
              })
            }
    },
    async getWeixinLogin(code) {
        // 请求 判断是否有绑定微信
        let res = await api.student.wxLogin({ code: code })
        if (res.code === 200) {
            let data = res.data;
            this.nickname = data.nickname;
            this.refreshToken = data.refresh_token;
            // data.code == 100 表示未绑定微信号
            if (data.code === '100') {
                this.wxLoginVisible = false;
                //弹出绑定微信账号页
                this.bindingWxLoginVisible = true;
            } else {
                await this.$store.dispatch('loginSuccess', {
                    user: res.data || {},
                    role: 'STUDENT'
                })
                this.$router.replace(this.redirect)
            }
        }
    },
    async cancel() {
        this.bindingWxLoginVisible = false;
    },
    async binding() {
        this.bindingWxLoginVisible = false;
        this.checkBinding = true;
        this.showBinding = true;
        this.showWxLogin = false;
    },
  }
}
</script>

<style lang="scss" scoped>
@import url('./index.scss');
.login-wrap {
  .forget {
    margin: 1rem 0;
  }
}
</style>
